import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import SEO from "../components/seo";
export default ({data}) => {
    const policy = data.policy;
    return (
        <Layout>
          <SEO title={policy.title}/>
          <div className="policy-document" dangerouslySetInnerHTML={{__html: policy.content}}></div>
        </Layout>
    )
}

export const query = graphql`
  query($slug: String) {
    policy: codewavePolicyDocument( slug: { eq: $slug }) {
      title,
      content
    }
  }
`